<template>
	<div class="flex" style="flex-direction: column;height: 100%;">
		<sm-card title="筛选查询" noHeader icon="el-icon-search" style="height:80px;">
			<el-form slot="body" class="flex" inline ref="form" :model="searchParm" label-width="120px">
				<el-row class="flex-1">
					<el-col :span="6">
						<el-form-item label="接收企业：">
							<el-select v-model="searchParm.entId" clearable :popper-append-to-body="false"
								placeholder="请选择">
								<el-option v-for="item in ents" :key="item.abbr" :label="item.abbr" :value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="发送人：">
							<el-input v-model="searchParm.userName" placeholder="用户ID/账号/手机/姓名"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="10">
						<el-row>
							<el-form-item label="上传时间：">
								<el-col :span="10">
									<el-date-picker v-model="searchParm.timeStart" value-format="yyyy-MM-dd HH:mm:ss"
										type="datetime" placeholder="请选择日期">
									</el-date-picker>
								</el-col>
								<el-col :span="2" style="text-align: center;">
									<span>-</span>
								</el-col>
								<el-col :span="10">
									<el-date-picker v-model="searchParm.timeEnd" value-format="yyyy-MM-dd HH:mm:ss"
										type="datetime" placeholder="请选择日期">
									</el-date-picker>
								</el-col>
							</el-form-item>
						</el-row>
					</el-col>
				</el-row>
				<div>
					<el-button type="primary" @click="list()">查询</el-button>
					<el-button plain @click="clearSearch()">重置</el-button>
				</div>
			</el-form>
		</sm-card>
		<div class="tableModule">
			<div class="title">
				<div class="title-left">
					<i class="el-icon-s-operation"></i>
					<span>数据列表</span>
				</div>
				<div class="title-right">
					<el-button type="primary" size="medium" @click="insert()" style="margin-right: 8px;">
						上传报告
					</el-button>
				</div>
			</div>
			<div class="tableContainer">
				<div class="table" ref="tableContainer">
					<div>
						<el-table ref="usertable" :data="tableData.records" border :height="tableHeight"
							:header-cell-style="{'background-color':'#f9fafc'}" :cell-style="{'padding':0}"
							style="margin-bottom: 20px;">
							<el-table-column align="center" label="序号" show-overflow-tooltip>
								<template slot-scope="scope">
									<div>{{scope.$index+1 }}</div>
								</template>
							</el-table-column>
							<el-table-column prop="title" align="center" label="标题" show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="entName" align="center" label="接收企业" show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="userName" align="center" label="发送人" show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="create_time" align="center" label="上传时间" show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="remarks" align="center" label="备注" show-overflow-tooltip>
							</el-table-column>
							<el-table-column fixed="right" align="center" label="操作" width="180">
								<template slot-scope="scope">
									<el-button type="text" @click="download(scope.row)">
										查看下载
									</el-button>
									<el-button type="text" @click="edit(scope.row)">
										编辑
									</el-button>
									<el-button type="text" @click="remove(scope.row)">
										删除
									</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
				<div class="pagination">
					<el-pagination style="float: right;" @current-change="handleCurrentChange"
						:current-page.sync="searchParm.current" :page-size="searchParm.size"
						layout="total, prev, pager, next,jumper" :total="tableData.total">
					</el-pagination>
				</div>
			</div>
		</div>

		<edit :isShow.sync="showEditDialog" :dialogObj="editObj"></edit>
		<downloadList :isShow.sync="showDownloadListDialog" :dialogObj="downloadListObj"></downloadList>
	</div>
</template>
<script>
	import smCard from "@c/smCard.vue"
	import smFooter from "@c/smFooter.vue"
	import edit from "./edit.vue"
	import downloadList from "./downloadList.vue"
	export default {
		components: {
			smCard,
			smFooter,
			edit,
			downloadList
		},
		data() {
			return {
				tableHeight: 0,
				tableData: {
					records: [],
				},
				searchParm: {
					size: 20,
					current: 1
				},
				ents: [],
				showEditDialog: false,
				showDownloadListDialog: false,
				editObj: {},
				downloadListObj: {}
			}
		},
		methods: {
			//自适应表格高度
			calHeight() {
				this.$nextTick(() => {
					const rect = this.$refs.tableContainer.getBoundingClientRect()
					this.tableHeight = rect.height
				})
			},
			//获取企业列表
			getEnts() {
				this.$get("/backend-api/sys/ent/list", {
					size: 99
				}).then((res) => {
					if (res.code == 1000) {
						this.ents = res.data.records;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			//清空搜索
			clearSearch() {
				this.searchParm = {
					size: 20,
					current: 1
				}
			},
			//展示数据
			list() {
				this.$get("/base-api/report_repair/list", this.searchParm).then((res) => {
					if (res.code == 1000) {
						this.tableData = res.data;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			//下载
			download(row) {
				this.downloadListObj = {};
				this.downloadListObj["id"] = row.id;
				this.showDownloadListDialog = true;
			},
			//删除
			remove(row) {
				this.$confirm("是否确认删除？", "删除健康状况报告", {
					dangerouslyUseHTMLString: true,
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$post("/base-api/report_repair/delete", {
						id: row.id
					}).then((res) => {
						if (res.code == 1000) {
							this.$message.success(res.msg);
							//刷新表格
							this.list();
						} else {
							this.$message.error(res.msg);
						}
					});
				}).catch(() => {});
			},
			//打开查看弹窗
			look(row) {
				this.editObj = {};
				this.editObj["id"] = row.id;
				this.editObj["type"] = 0;
				this.showEditDialog = true;
			},
			//打开编辑弹窗
			edit(row) {
				this.editObj = {};
				this.editObj["id"] = row.id;
				this.editObj["type"] = 1;
				this.showEditDialog = true;
			},
			//打开新增弹窗
			insert() {
				this.editObj = {};
				this.editObj["type"] = 2;
				this.showEditDialog = true;
			},
			//分页选择页面回调
			handleCurrentChange(val) {
				this.searchParm.current = val;
				this.list();
			},
		},
		mounted() {
			this.list();
			this.getEnts();
			this.calHeight();
			window.addEventListener('resize', this.calHeight)
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.calHeight)
		},
	};
</script>
<style lang='scss' scoped>
	@import "@style/userCenter.scss"
</style>
